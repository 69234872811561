import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Card,
  Row,
  Col,
  notification,
  Divider,
} from 'antd';
import { LinkOutlined, InfoCircleOutlined, EditOutlined } from '@ant-design/icons';
import {
  Button,
  Status,
  Information,
} from 'components/gyramais';

import './styles.less';
import { CcbModal } from 'components/modals';
import { EditClientDrawer, RevenueStatementDrawer } from 'components/drawers';

import PreApprovedDrawer from './components/PreApprovedDrawer';
import PendingDrawer from './components/PendingDrawer';

const ClientCard = ({
  business: {
    id,
    name,
    legalName,
    cnpj,
    loans,
    owner,
    segment,
    revenueAmount,
    employeesNumber,
  } = {},
  loading,
  business,
  refetch,
  detailed,
  aggregated,
  headerButton,
}) => {
  const history = useHistory();
  const [editClientDrawerVisible, setEditClientDrawerVisible] = useState(false);
  const [ccbModal, setCcbModal] = useState(false);

  const ccb = loans.filter((loan) => loan.ccb);
  const [preApprovedDrawerVisible, setPreApprovedDrawerVisible] = useState(false);
  const [pendingDrawerVisible, setPendingDrawerVisible] = useState(false);
  const [revenueStatementDrawer, setRevenueStatementDrawer] = useState(false);

  const loan = loans?.[0];

  const getStatusValue = () => (
    loans?.[0]?.status?.value.includes('awaiting-re-evaluation')
      ? 'awaiting-re-evaluation'
      : loans?.[0]?.status?.value
  );

  const getStatusName = () => (
    loans?.[0]?.status?.value.includes('awaiting-re-evaluation')
      ? 'Reavaliação'
      : loans?.[0]?.status?.name
  );
  return (
    <Card id="client-card" className={aggregated ? 'aggregated-card' : ''} key={id}>
      <Row className="client-card-header" align="middle" justify="space-between" gutter={[16, 16]}>
        <Col className="header-left-side" xs={24} sm={24} md={12} lg={16}>
          <div className="title-container">
            <h3 className="no-margin-bottom">
              {name || legalName || 'SEM NOME'}
            </h3>
          </div>

          {loans?.[0] && (
            <Status name={getStatusName()} value={getStatusValue()} />
          )}
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} className="header-right-side">
          <Row align="middle" justify="end" gutter={[16, 16]}>
            {headerButton && (
              <Col xs={24} sm={24} md={12}>
                {headerButton}
              </Col>
            )}
            <Col xs={24} sm={24} md={12}>
              <Button
                block
                icon={<EditOutlined />}
                link
                onClick={() => setEditClientDrawerVisible(true)}
              >
                <span>Editar Cliente</span>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      {owner
        && !owner.emailVerified
        && ['Novo (Lead)', 'Integrado', 'Pendente', 'Pré-Aprovado'].includes(loans?.[0]?.status?.name)
        && !detailed
        && (
          <Row align="middle" className="activation-link" gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12}>
              <span className="activation-link-text">
                <InfoCircleOutlined />
                {' '}
                Pendente Ativação
              </span>
            </Col>

            <Col xs={24} sm={24} md={12} align="right">
              <Button
                className="gyramais-btn gyramais-btn-link gyramais-btn-icon"
                icon={<LinkOutlined />}
                onClick={async () => {
                  navigator
                    .clipboard
                    .writeText(
                      `${process.env.GYRAMAIS_APP_URL}/cadastro/senha`
                      + `?password-token=${owner?.passwordToken}&verification-code=${owner?.emailVerificationCode}`,
                    );
                  notification.success({ message: 'Link copiado com sucesso!' });
                }}
              >
                Copiar link de ativação
              </Button>
            </Col>
          </Row>
        )}

      {
        ['Pré-Aprovado'].includes(loan?.status?.name)
        && !detailed
        && (
          <Row align="middle" className="activation-link" gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12}>
              <span className="activation-link-text">
                <InfoCircleOutlined />
                {' '}
                Escolher valor Pré-aprovado
              </span>
            </Col>

            <Col xs={24} sm={24} md={12} align="right">
              <Button
                className="gyramais-btn gyramais-btn-link gyramais-btn-icon"
                onClick={() => {
                  setPreApprovedDrawerVisible(true);
                }}
              >
                Escolher valor
              </Button>
            </Col>
          </Row>
        )
      }
      {
        ['Pendente', 'Cadastrado', 'Conferir documentos'].includes(loan?.status?.name)
        && !detailed
        && (
          <Row align="middle" className="activation-link" gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12}>
              <span className="activation-link-text">
                <InfoCircleOutlined />
                {' '}
                {loan?.status?.name === 'Pendente' ? 'Empresa pendente' : 'Empresa em análise'}
              </span>
            </Col>

            <Col xs={24} sm={24} md={12} align="right">
              <Button
                className="gyramais-btn gyramais-btn-link gyramais-btn-icon"
                onClick={() => {
                  setPendingDrawerVisible(true);
                }}
              >
                Adicionar informações e documentos
              </Button>
            </Col>
          </Row>
        )
      }

      {['new', 'lead'].includes(loans?.[0]?.status?.value) && !detailed && (
        <Row align="middle" className="activation-link" gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12}>
            <span className="activation-link-text">
              <InfoCircleOutlined />
              {' '}
              Enviar Faturamento
            </span>
          </Col>

          <Col xs={24} sm={24} md={12} align="right">

            <Button
              className="gyramais-btn gyramais-btn-link gyramais-btn-icon"
              onClick={() => setRevenueStatementDrawer(true)}
            >
              Ver/Editar Faturamento
            </Button>
          </Col>
        </Row>
      )}

      {['Assinatura'].includes(loans?.[0]?.status?.name) && !detailed
        && (
          <Row align="middle" className="activation-link" gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12}>
              <span className="activation-link-text">
                <InfoCircleOutlined />
                {' '}
                Assinatura ccb
              </span>
            </Col>

            <Col xs={24} sm={24} md={12} align="right">
              <Button
                className="gyramais-btn gyramais-btn-link gyramais-btn-icon"
                icon={<LinkOutlined />}
                onClick={async () => {
                  navigator
                    .clipboard
                    .writeText(`${process.env.GYRAMAIS_APP_URL}/login?cnpj=${business.cnpj}&cpf=${business.owner.cpf}`);
                  notification.success({ message: 'Link copiado com sucesso!' });
                }}
              >
                Copiar link de acesso ao portal
              </Button>
            </Col>
          </Row>
        )}

      {['Assinatura'].includes(loans?.[0]?.status?.name) && !detailed
        && (
          <Row align="middle" className="activation-link" gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12}>
              <span className="activation-link-text">
                <InfoCircleOutlined />
                {' '}
                Documento CCB
              </span>
            </Col>

            <Col xs={24} sm={24} md={12} align="right">
              <Button
                className="gyramais-btn gyramais-btn-link gyramais-btn-icon"
                icon={<LinkOutlined />}
                onClick={async () => {
                  window.open(ccb[0].ccb.url);
                }}
              >
                Abrir link para CCB
              </Button>
            </Col>
          </Row>
        )}

      {['Assinatura'].includes(loans?.[0]?.status?.name) && !detailed
        && (
          <Row align="middle" className="activation-link" gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12}>
              <span className="activation-link-text">
                <InfoCircleOutlined />
                {' '}
                Adicionar CCB
              </span>
            </Col>

            <Col xs={24} sm={24} md={12} align="right">
              <Button
                className="gyramais-btn gyramais-btn-link gyramais-btn-icon"
                icon={<LinkOutlined />}
                onClick={async () => {
                  setCcbModal(true);
                }}
              >
                Adicionar CCB
              </Button>
            </Col>
          </Row>
        )}

      {
        detailed ? (
          <Divider />
        ) : (
          <br />
        )
      }

      <Row align="middle" gutter={[16, 16]}>
        <Col xs={24} sm={24} md={detailed ? 24 : 19}>
          <Row align="middle" gutter={[{ xs: 32, sm: 32, md: 56 }, 16]}>
            <Col>
              <Information
                label="Razão social"
                value={legalName}
              />
            </Col>
            <Col>
              <Information
                label="CNPJ"
                value={cnpj}
              />
            </Col>

            {detailed && (
              <>
                {owner?.fullName && (
                  <Col>
                    <Information
                      detailed
                      label="Nome Sócio Principal"
                      value={owner.fullName}
                    />
                  </Col>
                )}
                {owner?.email && (
                  <Col>
                    <Information
                      detailed
                      label="Email Sócio Principal"
                      value={owner.email}
                    />
                  </Col>
                )}
                {segment?.name && (
                  <Col>
                    <Information
                      detailed
                      label="Ramo de atuação"
                      value={segment.name}
                    />
                  </Col>
                )}
                {revenueAmount && (
                  <Col>
                    <Information
                      detailed
                      label="Faturamento"
                      value={revenueAmount}
                      type="currency"
                    />
                  </Col>
                )}
                {!!employeesNumber && (
                  <Col>
                    <Information
                      detailed
                      label="Numero de funcionários"
                      value={employeesNumber}
                    />
                  </Col>
                )}
              </>
            )}
          </Row>
        </Col>
        {!detailed && (
          <Col xs={24} sm={24} md={5}>
            <Button
              className="button-details"
              onClick={() => history.push(`cliente/${id}`)}
              onMouseDown={(event) => {
                if (event.button === 1) {
                  window.open(`/cliente/${id}`, '_blank');
                }
              }}
              block
            >
              Ver detalhes
            </Button>
          </Col>
        )}
      </Row>

      <PreApprovedDrawer
        loanId={loan?.id}
        loanFinalAmount={loan?.finalAmount}
        loanPortfolioName={loan?.portfolioName}
        visible={preApprovedDrawerVisible}
        refetch={refetch}
        onClose={() => setPreApprovedDrawerVisible(false)}
      />

      <PendingDrawer
        loan={loan}
        business={business}
        businessId={business.id}
        refetch={refetch}
        visible={pendingDrawerVisible}
        onClose={() => setPendingDrawerVisible(false)}
      />

      <EditClientDrawer
        visible={editClientDrawerVisible}
        onClose={() => setEditClientDrawerVisible(false)}
        business={business}
        refetch={refetch}
      />
      {
        loans?.[0] && (
          <>

            <CcbModal
              visible={ccbModal}
              onClose={() => setCcbModal(false)}
              loanId={loans?.[0].id}
              refetch={refetch}
              loading={loading}
            />
            <RevenueStatementDrawer
              visible={revenueStatementDrawer}
              onClose={() => setRevenueStatementDrawer(false)}
              business={business}
              businessId={business.id}
              loanId={loans?.[0].id}
              revenueStatement={business.revenueStatement}
              statements={business.revenueStatementValues}
              refetch={refetch}
              loading={loading}
            />
          </>
        )
      }
    </Card>
  );
};

ClientCard.propTypes = {
  refetch: PropTypes.func,
  business: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    legalName: PropTypes.string,
    cnpj: PropTypes.string,
    loans: PropTypes.arrayOf(PropTypes.shape({
      interestRate: PropTypes.number,
      status: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      }),
    })),
    owner: PropTypes.shape({
      emailVerified: PropTypes.bool,
      passwordToken: PropTypes.string,
      emailVerificationCode: PropTypes.string,
      fullName: PropTypes.string,
      email: PropTypes.string,
    }),
    segment: PropTypes.shape({
      name: PropTypes.string,
    }),
    revenueAmount: PropTypes.number,
    employeesNumber: PropTypes.number,
  }),
  loading: PropTypes.bool,
  detailed: PropTypes.bool,
  aggregated: PropTypes.bool,
  headerButton: PropTypes.shape(),
};

export default ClientCard;
