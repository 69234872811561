import React, { useState, useEffect } from 'react';
import {
  message,
  Steps,
  Form,
  Tooltip,
} from 'antd';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Drawer,
} from 'components/gyramais';
import './styles.less';

import { useLazyQuery, useMutation } from '@apollo/client';
import { formatError } from 'utils';

import BusinessInfoStep from './components/BusinessInfo';
import AddressForm from './components/AddressForm';
import BusinessDocuments from './components/BusinessDocuments';
import BankAccount from './components/BankAccount';
import BusinessUser from './components/BusinessUser';

import SAVE_ADDRESS from './graphql/saveAddress.gql';
import BUSINESS from './graphql/business.gql';
import SAVE_BUSINESS from './graphql/saveBusiness.gql';

const { useForm } = Form;

const PendingDrawer = ({
  business,
  visible,
  onClose,
  refetch,
}) => {
  const [form] = useForm();

  const [activeTab, setActiveTab] = useState('info');
  const [
    fetchThisBusiness,
    {
      data: { business: businessCached } = {},
      loading: fetchThisBusinessLoading,
    },
  ] = useLazyQuery(BUSINESS, { fetchPolicy: 'network-only' });

  const [saveAddress, { loading: addressLoading }] = useMutation(SAVE_ADDRESS, {
    onError: (error) => {
      message.error(formatError(error));
    },
  });

  const [saveBusiness, { loading: businessLoading }] = useMutation(SAVE_BUSINESS, {
    onCompleted: () => {
      setActiveTab('address');
      refetch();
    },
    onError: (error) => {
      message.error(formatError(error));
    },
  });

  const [
    saveBusinessToComplete,
    { loading: businessToCompleteLoading },
  ] = useMutation(SAVE_BUSINESS, {
    onCompleted: () => {
      onClose();
      setActiveTab('address');
      refetch();
    },
    onError: (error) => {
      message.error(formatError(error));
    },
  });

  const loading = addressLoading
   || fetchThisBusinessLoading
   || businessLoading
   || businessToCompleteLoading;

  const handleClose = () => {
    if (loading) {
      return;
    }

    onClose();
  };

  const stepList = [
    {
      key: 'info',
      name: 'Informações',
      completed: business?.platformType?.value
      && business?.sector?.value
      && business?.segment?.value
      && business?.employeesNumber
      && business?.revenueAmount,
      bodyElement: (
        <BusinessInfoStep
          businessId={business.id}
          form={form}
          loading={loading}
        />
      ),
      submitButtonText: 'Salvar',
      onSubmit: async () => {
        saveBusiness({
          variables: {
            id: business.id,
            platformTypeValue: form.getFieldValue('platformType'),
            sectorValue: form.getFieldValue('sector'),
            segmentValue: form.getFieldValue('segment'),
            employeesNumber: Number(form.getFieldValue('employeesNumber')),
            revenueAmount: Number(form.getFieldValue('revenueAmount')),
          },
        });
      },
    },
    {
      key: 'address',
      name: 'Endereço',
      completed: business?.address?.id,
      bodyElement: (
        <AddressForm
          address={business.address ?? undefined}
          form={form}
          loading={loading}
        />
      ),
      submitButtonText: 'Salvar',
      onSubmit: async () => {
        await saveAddress({
          variables: {
            postalCode: form.getFieldValue('postalCode'),
            number: form.getFieldValue('number'),
            complement: form.getFieldValue('complement'),
            street: form.getFieldValue('street'),
            district: form.getFieldValue('district'),
            city: form.getFieldValue('city'),
            state: form.getFieldValue('state'),
            id: business.address?.id,
            businessId: business.id,
          },
        });
        await fetchThisBusiness({
          variables: {
            id: business.id,
          },
        });
        setActiveTab('documents');
      },
    }, {
      key: 'documents',
      name: 'Documentos',
      completed: (
        businessCached?.bylaws?.id
        && businessCached?.address?.proofOfAddress?.id
        && businessCached?.additionalFiles?.find((currentFile) => currentFile.parent?.name === 'raisPerson')
      ),
      bodyElement: (
        <BusinessDocuments
          businessId={business.id}
          loading={loading}
          onFileUpdated={() => fetchThisBusiness({ variables: { id: business.id } })}
        />
      ),
      submitButtonText: 'Próximo',
      onSubmit: async () => {
        setActiveTab('bankAccount');
      },
    }, {
      key: 'bankAccount',
      name: 'Dados bancários',
      completed: businessCached?.bankAccount?.id,
      bodyElement: (
        <BankAccount
          businessId={business.id}
          loading={loading}
        />
      ),
      submitButtonText: 'Próximo',
      onSubmit: async () => {
        setActiveTab('businessUsers');
      },
    }, {
      key: 'businessUsers',
      name: 'Sócios',
      completed: businessCached?.users.every((user) => user.completedAt),
      bodyElement: (
        <BusinessUser
          businessId={business.id}
          loading={loading}
        />
      ),
    },
  ];

  const STEP_MAP = stepList.reduce((acc, item, index) => {
    acc[item.key] = index;
    return acc;
  }, {});

  const currentStep = stepList.find((list) => list.key === activeTab);
  useEffect(() => {
    if (!visible) {
      return;
    }

    fetchThisBusiness({
      variables: {
        id: business.id,
      },
    });
  }, [visible]);
  return (
    <Drawer
      open={visible}
      onClose={handleClose}
      className="pending-drawer"
      width="70%"
      destroyOnClose
      placement="right"
      primaryButton={{
        visible: false,
      }}
      extraFooterComponents={(
        <div className="footer-container">
          {currentStep.onSubmit && (
            <Button
              loading={loading}
              onClick={() => {
                currentStep.onSubmit();
              }}
            >
              {currentStep.submitButtonText}
            </Button>
          )}
          {stepList.every((item) => item.completed) && (
            <Button
              loading={loading}
              onClick={() => {
                saveBusinessToComplete({
                  variables: {
                    id: business.id,
                    completed: true,
                  },
                });
              }}
            >
              Completar empréstimo
            </Button>
          )}

          {!stepList.every((item) => item.completed) && (
            <>
              <Tooltip
                overlayStyle={{ whiteSpace: 'pre-line' }}
                title={`Você poderá completar o empréstimo quando todas as listas estiverem completas.\n 
No momento faltam:
 ${stepList.filter((item) => !item.completed).map((item) => item.name).join(', ')}`}
                trigger="hover"
              >
                <div>
                  <Button
                    disabled
                  >
                    Completar empréstimo
                  </Button>
                </div>

              </Tooltip>
            </>
          )}
        </div>
      )}
    >
      <Steps
        current={STEP_MAP[activeTab]}
        onChange={(index) => {
          const key = Object.keys(STEP_MAP)[index];
          setActiveTab(key);
        }}
        items={stepList.map((data) => ({
          title: data.name,
          icon: data.completed ? <CheckCircleOutlined /> : <ExclamationCircleOutlined />,
        }))}
        size="small"
        responsive
      />
      {currentStep?.bodyElement}
    </Drawer>
  );
};

export default PendingDrawer;
