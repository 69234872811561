import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  notification,
  Spin,
  Steps,
  Form,
} from 'antd';
import {
  Button,
  Card,
  Drawer,
  Input,
} from 'components/gyramais';
import {
  numberToMonth,
  formatError,
} from 'utils';
import {
  useMutation,
} from '@apollo/client';
import UploadCard from 'components/cards/UploadCard';
import { SAVE_BUSINESS_USER, VALIDATE_CPF } from 'services/graphql/mutations';
import {
  SAVE_BUSINESS,
  SAVE_LOAN_EVENT,
} from './graphql';
import './styles.less';

const { Item, useForm } = Form;

const RevenueStatementDrawer = ({
  visible,
  onClose,
  business,
  businessId,
  statements: businessStatements,
  loanId,
  revenueStatement,
  refetch,
  loading: businessLoading,
}) => {
  const [statements, setStatements] = useState([]);
  const [statementsFilesIds, setStatementsFilesIds] = useState(false);
  const [activeTab, setActiveTab] = useState('info');
  const [form] = useForm();

  const [saveLoanEvent, { loading: saveLoanLoading }] = useMutation(SAVE_LOAN_EVENT, {
    onCompleted: async () => {
      notification.success({ message: 'Empréstimo salvo com sucesso', duration: 10 });
      await refetch();
      onClose();
      refetch();
    },
    onError: (error) => notification.error({ message: formatError(error), duration: 10 }),
  });

  const [saveBusiness] = useMutation(SAVE_BUSINESS, {
    onCompleted: ({ saveBusiness: { revenueStatement } }) => {
      notification.success({ message: 'Arquivo alterado com sucesso', duration: 10 });
      refetch();
      if (revenueStatement.length) {
        setStatementsFilesIds(true);
      }
    },
    onError: (error) => notification.error({ message: formatError(error), duration: 10 }),
  });

  const [
    saveBusinessUser,
    {
      loading: loadingSaveBusinessUser,
    },
  ] = useMutation(SAVE_BUSINESS_USER, {
    onCompleted: async () => {
      setActiveTab('statements');
    },
    onError: (error) => notification.error({ message: formatError(error), duration: 10 }),
  });

  const [
    validateCPF,
    {
      loading: loadingValidateCPF,
    },
  ] = useMutation(VALIDATE_CPF, {
    onCompleted: async () => {
      saveBusinessUser({
        variables: {
          id: business.users.find((user) => user.user.id === business.owner.id).id,
          fullName: form.getFieldValue('fullName'),
          email: form.getFieldValue('email'),
          cpf: form.getFieldValue('cpf'),
          phoneNumber: form.getFieldValue('phoneNumber'),
        },
      });

    },
    onError: (error) => {
      notification.error({ message: formatError(error), duration: 10 });
    },
  });

  const validateStatements = () => {
    const statementsOrdered = statements.sort((a, b) => a.reference - b.reference);

    const statementsWithValue = statementsOrdered.filter(({ value }) => Boolean(Number(value)));
    return statementsWithValue.length >= 7;
  };

  useEffect(() => {
    const emptyStatements = [];
    for (let x = 1; x <= 12; x += 1) {
      const auxDate = new Date();
      const date = new Date(auxDate.getFullYear(), auxDate.getMonth() - x, 1, 0, 0, 0, 0);
      const mesano = `${date.getMonth() + 1}${date.getFullYear()}`;
      const statement = businessStatements
        ?.find(({ mesano: statementMesano }) => statementMesano === mesano);
      emptyStatements.push({
        id: x,
        label: `${numberToMonth(date.getMonth() + 1)} de ${date.getFullYear()}`,
        reference: new Date(`${date.getFullYear()}-${date.getMonth() + 1}-01`),
        value: statement?.value || 0,
        mesano,
      });
    }
    setStatements(emptyStatements.reverse());

  }, [businessStatements, visible]);

  useEffect(() => {
    if (revenueStatement.length) {
      setStatementsFilesIds(true);
    } else {
      setStatementsFilesIds(false);
    }
  }, [revenueStatement]);

  useEffect(() => {
    const {
      fullName,
      email,
      cpf,
      phoneNumber,
    } = business.owner;

    form.setFieldsValue({
      fullName,
      email,
      cpf,
      phoneNumber,
    });

  }, [business]);

  const loading = businessLoading || saveLoanLoading
  || loadingSaveBusinessUser || loadingValidateCPF;

  const stepList = [
    {
      key: 'info',
      name: 'Informações',
      bodyElement: (
        <Form
          layout="vertical"
          form={form}
        >
          <Row align="middle" id="add-user-account" gutter={24}>
            <Col xs={24} sm={24} md={12}>
              <Item
                label="Nome completo"
                name="fullName"
                rules={[{
                  required: true,
                  message: 'Por favor preencha o nome',
                }]}
              >
                <Input
                  placeholder="Nome completo"
                  defaultValue={business.owner.fullName}
                />
              </Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Item
                label="E-mail"
                name="email"
                rules={[{
                  required: true,
                  whitespace: true,
                  message: 'Por favor preencha o E-mail',
                }]}
              >
                <Input
                  placeholder="E-mail"
                  defaultValue={business.owner.email}
                />
              </Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Item
                label="CPF"
                name="cpf"
                rules={[{
                  required: true,
                  whitespace: true,
                  message: 'Por favor preencha o CPF',
                }]}
              >
                <Input
                  type="masked"
                  mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                  placeholder="CPF"
                  defaultValue={business.owner.cpf}
                />
              </Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Item
                label="TELEFONE CELULAR"
                name="phoneNumber"
                rules={[{
                  required: true,
                  whitespace: true,
                  message: 'Por favor preencha o Telefone',
                }]}
              >
                <Input
                  type="masked"
                  guide
                  mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                  defaultValue={business.owner.phoneNumber}
                  placeholder="Telefone celular"
                />
              </Item>
            </Col>
          </Row>
        </Form>
      ),
      submitButtonText: 'Próximo',
      onSubmit: async () => {
        if (!form.getFieldValue('cpf')) {
          notification.error({
            message: 'Por favor preencha o CPF',
          });
          return;
        }
        await validateCPF({ variables: { cpf: form.getFieldValue('cpf') } });
      },
    }, {
      key: 'statements',
      name: 'Faturamento',
      bodyElement: (
        <Spin spinning={loading}>
          <Card className="statements-inputs-card">
            {statements.map(({ label, id: index, value }) => (
              <Row align="middle" key={index} className="statement-input-area" gutter={[24, 24]}>
                <Col xs={24} sm={24} md={12}>
                  <div className="statment-label">{`Valor em ${label}`}</div>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Input
                    type="currency"
                    value={value}
                    onChange={async (value) => {
                      const statementId = statements.findIndex(({ id }) => id === index);
                      const all = statements;
                      all[statementId].value = value;
                      setStatements([...all]);
                    }}
                  />
                </Col>
              </Row>
            ))}
          </Card>

          <br />
          <br />
          <br />

          <UploadCard
            id="revenue-statement-upload"
            accept="application/pdf, .pdf"
            actualFile={revenueStatement}
            loading={loading}
            multiples
            refetch={refetch}
            alert="Relação de faturamento dos últimos 12 meses, mês a mês"
            label="Faturamento dos últimos 12 meses"
            onCompleted={async ({ id: fileId }) => {
              await saveBusiness({
                variables: {
                  id: businessId,
                  revenueStatementFilesIds:
                    [...revenueStatement?.map((i) => i.id), fileId],
                },
              });
            }}
          />
        </Spin>
      ),
      submitButtonText: 'Voltar',
      onSubmit: async () => {
        setActiveTab('info');
      },
    },
  ];

  const STEP_MAP = stepList.reduce((acc, item, index) => {
    acc[item.key] = index;
    return acc;
  }, {});

  const currentStep = stepList.find((list) => list.key === activeTab);
  return (
    <Drawer
      title="Cadastro inicial"
      visible={visible}
      primaryButton={{
        visible: false,
      }}
      maxWidth="100%"
      onClose={onClose}
      extraFooterComponents={(
        <div className="footer-container">
          {currentStep.onSubmit && (
            <Button
              loading={loading}
              onClick={() => {
                currentStep.onSubmit();
              }}
            >
              {currentStep.submitButtonText}
            </Button>
          )}
          {activeTab === 'statements' && (
            <Button
              loading={loading}
              onClick={async () => {
                if (!validateStatements()) {
                  return notification.error({
                    message: 'Preencha ao menos 7 campos de faturamento do ultimo ano para prosseguir',
                  });
                }
                if (!statementsFilesIds) {
                  return notification.error({
                    message: 'Envie o arquivo de faturamento dos últimos 12 meses',
                  });
                }
                if (!form.getFieldValue('cpf')) {
                  return notification.error({
                    message: 'Por favor preencha o CPF na etapa anterior',
                  });
                }

                await saveBusiness({
                  variables: {
                    id: businessId,
                    revenueStatementValues: statements.map(({
                      reference, value, mesano,
                    }) => ({ reference, value, mesano })),
                  },
                });

                saveLoanEvent({
                  variables: {
                    loanId,
                    statusValue: 'integrated',
                  },
                });

                onClose();
                return true;
              }}
            >
              Completar empréstimo
            </Button>
          )}
        </div>
      )}
    >
      <Form
        id="profile-step"
      >
        <Steps
          current={STEP_MAP[activeTab]}
          items={stepList.map((data) => ({
            title: data.name,
          }))}
          size="small"
          responsive
        />
      </Form>
      <br />
      {currentStep?.bodyElement}
    </Drawer>
  );
};

RevenueStatementDrawer.propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  refetch: PropTypes.func,
  statements: PropTypes.arrayOf(PropTypes.shape()),
  revenueStatement: PropTypes.arrayOf(PropTypes.shape()),
  businessId: PropTypes.string,
  loanId: PropTypes.string,
};

export default RevenueStatementDrawer;
