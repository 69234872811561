// @ts-check
import React, {
  useState,
  useMemo,
} from 'react';
import {
  notification,
  message,
  Tooltip,
  Steps,
  Form,
} from 'antd';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Button, Modal } from 'components/gyramais';

import { useQuery, useMutation } from '@apollo/client';
import { formatError } from 'utils';

import ProfileStep from './components/ProfileStep';
import CivilPartnerStep from './components/CivilPartnerStep';
import AddressStep from './components/AddressStep';
import DocumentsStep from './components/DocumentsStep';
import OptInStep from './components/OptInStep';

// @ts-ignore
import BUSINESS from '../../../../graphql/business.gql';
// @ts-ignore
import SAVE_BUSINESS_USER from './graphql/saveBusinessUser.gql';
// @ts-ignore
import SAVE_ADDRESS from './graphql/saveAddress.gql';
// @ts-ignore
import USER_BY_CPF from './graphql/getUserByCpf.gql';
// @ts-ignore
import COMPLETE_BUSINESS_USER from './graphql/completeBusinessUser.gql';

import './styles.less';

const { useForm } = Form;

const CreateOrEditUserModal = ({
  businessId,
  businessUserId,
  setBusinessUserId,
  visible,
  onClose,
  refetch,
}) => {
  const [activeTab, setActiveTab] = useState('profile');
  const [civilPartnerDoeNotExists, setCivilPartnerDoeNotExists] = useState(false);
  const [form] = useForm();

  const {
    data: {
      business,
    } = {},
    loading: businessLoading,
  } = useQuery(BUSINESS, {
    variables: {
      id: businessId,
    },
    skip: !businessId,
    fetchPolicy: 'cache-only',
  });

  const [
    getUserByCpf,
    { loading: userByCpfLoading },
  ] = useMutation(USER_BY_CPF);

  const [
    completeBusinessUser,
    { loading: completedBusinessUserLoading },
  ] = useMutation(COMPLETE_BUSINESS_USER, {
    onCompleted: async () => {
      await refetch();
      onClose();
      notification.success({ message: 'Sócio completo com sucesso', duration: 10 });
      setActiveTab('profile');
    },
  });

  const [
    saveBusinessUser,
    { loading: saveBusinessUserLoading },
  ] = useMutation(SAVE_BUSINESS_USER, {
    onCompleted: async ({ saveBusinessUser }) => {
      await refetch();
      if (!businessUserId) {
        setBusinessUserId(saveBusinessUser.id);
      }
      setCivilPartnerDoeNotExists(false);

      switch (activeTab) {
        case 'profile':
          if (['casado', 'uniao-estavel'].includes(saveBusinessUser?.user?.civilStatus?.value)) {
            setActiveTab('partner');
          } else {
            setActiveTab('address');
          }
          break;
        case 'partner':
          setActiveTab('address');
          break;
        default:
          break;
      }
    },
    onError: (error) => message.error(formatError(error), 2),
  });

  const [
    saveAddress,
    { loading: saveAddressLoading },
  ] = useMutation(SAVE_ADDRESS, {
    onCompleted: async () => {
      await refetch();
      setActiveTab('documents');
    },
    onError: (error) => message.error(formatError(error), 2),
  });

  const businessUser = useMemo(() => {
    const businessUser = business?.users?.find(({ id }) => id === businessUserId);
    return businessUser;
  }, [business, businessId, businessUserId, businessLoading]);

  const loading = businessLoading
    || saveBusinessUserLoading
    || saveAddressLoading
    || userByCpfLoading
    || completedBusinessUserLoading;

  const stepList = [
    {
      key: 'profile',
      name: 'Perfil',
      completed: businessUser?.user.fullName
        && businessUser?.user.cpf
        && businessUser?.user.civilStatus
        && businessUser?.user.birthDate
        && businessUser?.user.phoneNumber
        && businessUser?.user.email
        && businessUser?.user.additionalInfo?.gender,
      bodyElement: (
        <ProfileStep
          businessId={businessId}
          businessUserId={businessUserId}
          form={form}
        />
      ),
      textButton: 'Salvar informações do sócio',
      onSubmit: () => {
        if (businessUserId) {
          saveBusinessUser({
            variables: {
              id: businessUser.id,
              fullName: form.getFieldValue('fullName'),
              cpf: form.getFieldValue('cpf'),
              civilStatusId: form.getFieldValue('civilStatusId'),
              birthDate: form.getFieldValue('birthDate'),
              phoneNumber: form.getFieldValue('phoneNumber'),
              email: form.getFieldValue('email'),
              gender: form.getFieldValue('gender'),
            },
          });
        } else {
          saveBusinessUser({
            variables: {
              businessId,
              cpf: form.getFieldValue('cpf'),
              email: form.getFieldValue('email'),
            },
          });

        }
      },
    }, {
      key: 'partner',
      name: 'Cônjuge',
      completed: (
        (
          (
            ['casado', 'uniao-estavel'].includes(businessUser?.user?.civilStatus?.value)
            && businessUser?.user?.civilPartner?.id
          ) || (
            businessUser?.user?.civilStatus?.value
            && !['casado', 'uniao-estavel'].includes(businessUser?.user?.civilStatus?.value)
          )
        ) && businessUser
      ),
      disabled: !['casado', 'uniao-estavel'].includes(businessUser?.user?.civilStatus?.value) || !businessUser,
      bodyElement: (
        <CivilPartnerStep
          businessId={businessId}
          businessUserId={businessUserId}
          civilPartnerDoeNotExists={civilPartnerDoeNotExists}
          form={form}
        />
      ),
      textButton: 'Salvar informações do cônjuge',
      onSubmit: async () => {
        const civilPartner = {
          fullName: form.getFieldValue('civilPartnerFullName'),
          cpf: form.getFieldValue('civilPartnerCpf'),
          civilStatusId: form.getFieldValue('civilPartnerCivilStatusId'),
          birthDate: form.getFieldValue('civilPartnerBirthDate'),
          phoneNumber: form.getFieldValue('civilPartnerPhoneNumber'),
          email: form.getFieldValue('civilPartnerEmail'),
        };
        const civilPartnerExists = businessUser?.user?.civilPartner;
        if (!civilPartnerExists) {
          const {
            data: {
              userByCPF,
            },
          } = await getUserByCpf({ variables: { cpf: civilPartner.cpf } });

          if (userByCPF) {
            return saveBusinessUser({
              variables: {
                id: businessUser.id,
                civilPartner: {
                  id: userByCPF.id,
                  cpf: civilPartner.cpf,
                },
              },
            });
          }

          if (!civilPartner.email) {
            setCivilPartnerDoeNotExists(true);
            // eslint-disable-next-line consistent-return
            return;
          }

          return saveBusinessUser({
            variables: {
              id: businessUser.id,
              civilPartner,
            },
          });

        }
        civilPartner.id = civilPartnerExists.id;
        return saveBusinessUser({
          variables: {
            id: businessUser.id,
            civilPartner,
          },
        });

      },
    }, {
      key: 'address',
      name: 'Endereço',
      completed: (
        businessUser?.user?.address?.id
      ),
      disabled: !businessUser,
      textButton: 'Salvar endereço',
      bodyElement: (
        <AddressStep
          address={businessUser?.user?.address}
          form={form}
          loading={loading}
        />
      ),
      onSubmit: () => {
        const variables = {
          postalCode: form.getFieldValue('postalCode'),
          number: form.getFieldValue('number'),
          complement: form.getFieldValue('complement'),
          street: form.getFieldValue('street'),
          district: form.getFieldValue('district'),
          city: form.getFieldValue('city'),
          state: form.getFieldValue('state'),
        };

        if (businessUser.user.address) {
          variables.id = businessUser.user.address.id;
        } else {
          variables.userId = businessUser.user.id;
        }

        saveAddress({ variables });
      },
    }, {
      key: 'documents',
      name: 'Documentos',
      textButton: 'Seguir para opt-in',
      onSubmit: () => { setActiveTab('optins'); },
      completed: businessUser?.user.idCard
        && businessUser?.user.proofOfAddress
        && businessUser?.user.proofOfIncome
        && businessUser?.user.proofOfIncomeReceipt
        && (
          [
            'divorciado',
            'casado',
            'viuvo',
          ].includes(businessUser?.user.civilStatus?.value)
            ? businessUser?.user.proofOfMariageOrDivorce : true
        )
        && (
          [
            'solteiro',
            'uniao-estavel',
          ].includes(businessUser?.user.civilStatus?.value)
            ? businessUser?.user.proofOfCivilStatus : true
        ),
      disabled: !businessUser || (['solteiro', 'uniao-estavel'].includes(businessUser?.user?.civilStatus?.value) && !businessUser?.user?.address),
      bodyElement: (
        <DocumentsStep
          businessId={businessId}
          businessUserId={businessUserId}
          loading={loading}
        />
      ),
    }, {
      key: 'optins',
      name: 'Opt-ins',
      completed: businessUser?.user.optIns.length === 4,
      disabled: !businessUser?.user.cpf,
      bodyElement: (
        <OptInStep
          businessId={businessId}
          businessUserId={businessUserId}
          loading={loading}
        />
      ),
    },
  ];

  const STEP_MAP = stepList.reduce((acc, item, index) => {
    acc[item.key] = index;
    return acc;
  }, {});

  const currentStep = stepList.find((list) => list.key === activeTab);

  const handleClose = () => {
    onClose();
    setActiveTab('profile');
  };
  return (
    <Modal
      className="add-user-account-modal new-gyramais-styles"
      visible={visible}
      onCancel={handleClose}
      destroyOnClose
      centered
      title={businessUserId ? 'Editar sócio' : 'Adicionar sócio'}
      okText="Fechar"
      width="70%"
      cancelButtonProps={{ hidden: true }}
      footer={(
        <div className="footer-container">
          {currentStep.onSubmit && (
            <Button
              loading={loading}
              onClick={() => {
                currentStep.onSubmit();
              }}
            >
              {currentStep.textButton ?? 'Salvar'}
            </Button>
          )}
          {stepList.every((item) => item.completed) && (
            <Button
              loading={loading}
              onClick={() => completeBusinessUser({
                variables: {
                  id: businessUser.id,
                },
              })}
            >
              Completar sócio
            </Button>
          )}

          {!stepList.every((item) => item.completed) && (
            <>
              <Tooltip
                overlayStyle={{ whiteSpace: 'pre-line' }}
                title={`Você poderá completar o sócio quando todas as listas estiverem completas.\n 
No momento faltam:
 ${stepList.filter((item) => !item.completed).map((item) => item.name).join(', ')}`}
                trigger="hover"
              >
                <div>
                  <Button
                    disabled
                  >
                    Completar sócio
                  </Button>
                </div>

              </Tooltip>
            </>
          )}
        </div>
      )}
    >
      <Steps
        current={STEP_MAP[activeTab]}
        onChange={(index) => {
          const key = Object.keys(STEP_MAP)[index];
          setActiveTab(key);
        }}
        items={stepList.map((data) => ({
          title: data.name,
          icon: data.completed ? <CheckCircleOutlined /> : <ExclamationCircleOutlined />,
          disabled: data.disabled,
        }))}
        size="small"
        responsive
      />
      <div className="body">
        {currentStep?.bodyElement}
      </div>
    </Modal>
  );
};

export default CreateOrEditUserModal;
